import React from 'react';
import styled from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links, colors} from 'global.config';
import Stars from 'components/Stars';
import TopSectionImage from 'images/zapier-illustration.svg';
import ZapierLogoImage from 'images/zapier-logo.svg';
import {AnchorLink} from 'components/AnchorLink';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 150px;
  position: relative;
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 650px;
  margin: 15px auto 50px;
  font-weight: 300;
`;

const ZapierLogo = styled.img`
  vertical-align: text-top;
  @media ${media.tablet} {
    width: 145px;
  }
  @media ${media.mobile} {
    width: 150px;
    margin-top: 15px;
  }
`;

const ZapierInstructions = styled.div`
  color: ${colors.text};
  max-width: 850px;
  margin: -150px auto 150px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: 50px;
  @media ${media.mobile} {
    padding: 30px 20px;
    width: 96%;
  }
  ul {
    text-align: left;
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    li {
      padding: 6px 0 6px 18px;
      position: relative;
      &:before {
        content: '\u2022';
        color: ${colors.action};
        font-size: 28px;
        line-height: 17px;
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }
`;

const Page: React.FC<{}> = ({}) => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Zapier')}>
        <meta
          itemProp="description"
          content={t(
            'Zapier automates your tedious workflows by connecting MoonMail to 750+ other business tools. Try it now!'
          )}
        />
        <link itemProp="applicationCategory" href="http://schema.org/OtherApplication" />
        <meta itemProp="applicationCategory" content="Email Marketing Software" />
        <meta itemProp="url" content="https://moonmail.io/zapier/" />
        <meta itemProp="name" content="MoonMail for Zapier" />
        <meta itemProp="fileformat" content="application/zip" />
        <script src="https://zapier.com/apps/embed/widget.js?services=moonmail&html_id=zapier-frame&limit=100"></script>
      </Helmet>
      <div>
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Zapier Email marketing software')}
                title={t('Zapier Email marketing software')}
                width={296}
                height={201}
              />
              <h1>
                <Trans>
                  Upgrade Your Ship with{' '}
                  <ZapierLogo src={ZapierLogoImage} alt={t('Zapier for MoonMail')} />
                </Trans>
              </h1>
              <IndexSectionSummary>
                <Trans>
                  Zapier automates your tedious workflows by connecting MoonMail to 750+ other
                  business tools, including Slack, Gmail, and Trello.
                </Trans>
              </IndexSectionSummary>
            </IndexSectionContainer>
          </IndexSection>
        </Stars>
        <ZapierInstructions>
          <ul>
            <li>
              <Trans>Zapier integrates MoonMail with 750+ business apps</Trans>
            </li>
            <li>
              <Trans>Set up automations called Zaps to take care of repetitive tasks for you</Trans>
            </li>
            <li>
              <Trans>No coding required</Trans>
            </li>
            <li>
              <Trans>
                <AnchorLink external target="_blank" href="https://zapier.com/">
                  Sign up for a free Zapier account
                </AnchorLink>{' '}
                to use these popular automated workflows called Zaps
              </Trans>
            </li>
            <li>
              <Trans>
                Check out popular integrations listed below or{' '}
                <AnchorLink
                  external
                  target="_blank"
                  href="https://zapier.com/apps/moonmail/integrations">
                  create your own
                </AnchorLink>
              </Trans>
            </li>
          </ul>
          <div id="zapier-frame">
            <Trans>Loading zapps...</Trans>
          </div>
        </ZapierInstructions>
      </div>
    </>
  );
};

export default Page;
